.profile-picture{
    display: flex;
    align-items: center;
    justify-content: left;
    box-shadow: 0 1px 0px 0px var(--white);
    border-radius: 50%;
    height: 380px;
    width: 380px;
    margin: 0 0px 0 100px;
}
.profile-picture-background{
    height: 20%;
    width: 20%;
    background-image: url("../../assets/images/myPhoto.jpeg") !important;
    background-size: cover;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
}
.profile-picture-background:hover{
    transform: scale(1.07);
    transition: 1s ease-out;

}